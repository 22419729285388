import React from "react"
import LayoutSite from "./layouts/layoutSite"
import LayoutNews from "./layouts/layoutNews"
import ArticleSeo from "./crumbs/articleSeo"
import { graphql } from "gatsby"
import Boxes from "./crumbs/boxes"
import Ads from "../components/ads"

const getCover = (article) => {
    if (article.cover === null || article.cover.childImageSharp === null || article.cover.childImageSharp.fluid === null) {
        return null
    } else {
        return article.cover.childImageSharp.fluid
    }
}

const getCoverUrl = (article) => {
    if (article.cover === null || article.cover.publicURL === null) {
        return ""
    } else {
        return article.cover.publicURL
    }
}

export default function Article({ pageContext, data }) {
    const article = data.allStrapiArticle.edges[0].node
    const canonical = "https://www.mindenpercben.hu" + pageContext.slug
    const canonicalMobile = "https://www.m.mindenpercben.hu" + pageContext.slug
    const ogImage = "https://www.mindenpercben.hu" + getCoverUrl(article)

    const relatedRow1 = data.related.edges.slice(0, 3).map(item =>   <Boxes box={16} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const relatedRow2 = data.related.edges.slice(3, 6).map(item =>   <Boxes box={16} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const relatedRow3 = data.related.edges.slice(6, 9).map(item =>   <Boxes box={16} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const relatedRow4 = data.related.edges.slice(9, 12).map(item =>  <Boxes box={16} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const relatedRow5 = data.related.edges.slice(12, 15).map(item => <Boxes box={16} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const relatedRow6 = data.related.edges.slice(15, 18).map(item => <Boxes box={16} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const relatedRow7 = data.related.edges.slice(18, 21).map(item => <Boxes box={16} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    
    const belfold = data.belfold.edges.map(item =>   <Boxes box={6} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const kulfold = data.kulfold.edges.map(item =>   <Boxes box={6} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const gazdasag = data.gazdasag.edges.map(item => <Boxes box={6} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const kultura = data.kultura.edges.map(item =>   <Boxes box={6} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const bulvar = data.bulvar.edges.map(item =>     <Boxes box={6} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const techtud = data.techtud.edges.map(item =>   <Boxes box={6} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const eletmod = data.eletmod.edges.map(item =>   <Boxes box={6} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const idojaras = data.idojaras.edges.map(item => <Boxes box={6} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)

    return (
        <>
            <ArticleSeo
                title={ article.title }
                description={ article.excerpt }
                keywords={ article.tags.map(_ => _.name).join(',') }
                canonical={ canonical }
                canonicalMobile={ canonicalMobile }
                cover={ ogImage }
                coverWidth="600"
                coverHeight="400"
            />
            <LayoutSite>
                <LayoutNews
                    article={article}
                    relatedRow1={relatedRow1}
                    relatedRow2={relatedRow2}
                    relatedRow3={relatedRow3}
                    relatedRow4={relatedRow4}
                    relatedRow5={relatedRow5}
                    relatedRow6={relatedRow6}
                    relatedRow7={relatedRow7}
                    belfold={belfold}
                    kulfold={kulfold}
                    gazdasag={gazdasag}
                    techtud={techtud}
                    kultura={kultura}
                    eletmod={eletmod}
                    bulvar={bulvar}
                    idojaras={idojaras}
                />
            </LayoutSite>
        </>
    )
}

export const pageQuery = graphql`
    query GetArticle($articleID: String, $category: String) {
        belfold: allStrapiArticle(limit: 3, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Belföld"}}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }
    
        kulfold: allStrapiArticle(limit: 3, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Külföld"}}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }
    
        gazdasag: allStrapiArticle(limit: 3, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Gazdaság"}}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }
    
        techtud: allStrapiArticle(limit: 3, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "TechTud"}}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }
    
        kultura: allStrapiArticle(limit: 3, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Kultúra"}}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }
    
        bulvar: allStrapiArticle(limit: 3, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Bulvár"}}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }
    
        eletmod: allStrapiArticle(limit: 3, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Életmód"}}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }
    
        idojaras: allStrapiArticle(limit: 3, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Időjárás"}}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }

        allStrapiArticle(filter: {id: {eq: $articleID}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    content
                    tags {
                        name
                    }
                    user {
                        username
                    }
                    publish
                    cover {
                        publicURL                    
                    }                
                }
            }
        }

        related: allStrapiArticle(limit: 21, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: $category}}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`