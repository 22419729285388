import React from "react"
import ReactMarkdown from 'react-markdown'
import { Link } from "gatsby"
import { slugifyText, nicefyDate } from "../../utils/utils"
import BoxAd from "../crumbs/boxAd"

const getCoverUrl = (article) => {
    if (article.cover === null || article.cover.publicURL === null) {
        return ""
    } else {
        return article.cover.publicURL
    }
}

export default function LayoutNews({
    article,
    relatedRow1, relatedRow2, relatedRow3, relatedRow4, relatedRow5, relatedRow6, relatedRow7,
    belfold, kulfold, gazdasag, techtud, kultura, eletmod, bulvar, idojaras
}) {    
    const published = nicefyDate(article.publish)
    const excerpt = <ReactMarkdown source={article.excerpt} />
    const paragraphs = article.content.replace(/\n\n/ig, '\n').split('\n').filter(_ => _.length > 0).map(p => <ReactMarkdown source={p} />)
    if (paragraphs.length >= 5) {
        const ad = <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/1213119.html" width="700" height="300" frameborder="0" scrolling="no"></iframe> } />
        paragraphs.splice(4, 0, ad)
    }
    const coverUrl = getCoverUrl(article)

    return (
        <>
            <div className="wrap-news-block">
                <div className="container-fluid">
                    <div className="block-part">
                        <div className="row row-glairy">
                            <div className="col-glairy col-xs-12 col-md-8">
                                <div className="row row-glairy">
                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="content content-hu">
                                            <div className="category">{ article.category.name }</div>
                                            <h1>{ article.title }</h1>
                                            <div className="content-info"><span>{ published }</span><span> | { article.user.username }</span></div>

                                            <div className="lead">{ excerpt }</div>
                                            <div className="text">
                                                <div className="post_image post_image_fixed post_image_center">
                                                    <div className="post_image_fill">
                                                        {/*<Img className="image-full-width" fluid={article.cover.childImageSharp.fluid} alt={article.title} />*/}
                                                        { coverUrl && <img className="image-full-width" src={article.cover.publicURL}></img> }
                                                        <span className="post_image_figcaption">{ article.title }</span>
                                                    </div>
                                                </div>
                                                <script mode="block" data-zone="mp_adb_336280_zone1" src="https://head-clickfusion.com/client-v2.js"></script>
                                                <BoxAd
                                                    isBlock={false}
                                                    ad={
                                                        <iframe src="https://mindenpercben.hu/r/1213114.html" width="700" height="320" frameborder="0" scrolling="no"></iframe>
                                                        }
                                                />
                                                { paragraphs }
												<BoxAd
                                                isBlock={false}
                                                ad={ 
                                                      <div data-gz-block="17f029ff-a000-419d-88fd-926b9e8b1133"></div>                                                
                                                }
												/>
                                            </div>
                                            <div className="addthis_inline_share_toolbox"></div>
                                            <hr style={{ marginTop: `0px`, width: `100%`, color: `grey`, height: `2px`, backgroundColor: `grey` }} />
                                            <style type="text/css">{`
                                                .hashtags{ position:relative; top:-10px; } .hashtags a{ color: #307da8; }
                                            `}</style>
                                            <div className="hashtags" style={{ textTransform: `uppercase` }}>
                                                <ul>
                                                    { article.tags.map(tag => <li><Link to={ `/kereses/tag/${ slugifyText(tag.name) }/1` }>{ tag.name }</Link></li>) }
                                                </ul>
                                            </div>
                                            <script mode="block" data-zone="mp_adb_336280_zone2" src="https://head-clickfusion.com/client-v2.js"></script>
                                            <BoxAd
                                                isBlock={false}
                                                ad={ 
                                                    <iframe src="https://mindenpercben.hu/r/1213092.html" width="700" height="600" frameborder="0" scrolling="no"></iframe>                                                    
                                                }
                                            />
                                            <hr></hr>
                                        </div>
                                    </div>
                                    <div className="col-glairy col-xs-12 col-md-12" style={{ marginTop: `5px`, marginBottom: `20px` }}>
                                        <div className="box box-ajanlott">
                                            <div className="box-headline">
                                                <span style={{ color: `#18204c`, fontSize: `20px`, marginLeft: `10px` }}><b>További híreink</b></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="box box-middle">
                                            <div className="row row-glairy display-block">
                                                <div className="box-content">
                                                    { relatedRow1 }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="box box-middle">
                                            <div className="row row-glairy display-block">
                                                <div className="box-content">
                                                    { relatedRow2 }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="box box-middle">
                                            <div className="row row-glairy display-block">
                                                <div className="box-content">
                                                <script mode="block" data-zone="mp_adb_336280_zone3" src="https://head-clickfusion.com/client-v2.js"></script>
                                                <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/1213104.html" width="700" height="550" frameborder="0" scrolling="no"></iframe> } />
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                 

                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="box box-middle">
                                            <div className="row row-glairy display-block">
                                                <div className="box-content">
                                                    { relatedRow3 }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="box box-middle">
                                            <div className="row row-glairy display-block">
                                                <div className="box-content">
                                                <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/netex272.html" width="700" height="120" frameborder="0" scrolling="no"></iframe> } />
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="box box-middle">
                                            <div className="row row-glairy display-block">
                                                <div className="box-content">
                                                    { relatedRow4 }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="box box-middle">
                                            <div className="row row-glairy display-block">
                                                <div className="box-content">
                                                    { relatedRow5 }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="box box-middle">
                                            <div className="row row-glairy display-block">
                                                <div className="box-content">
                                                    { relatedRow6 }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="box box-middle">
                                            <div className="row row-glairy display-block">
                                                <div className="box-content">
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-glairy col-xs-12 col-md-12">
                                        <div className="box box-middle">
                                            <div className="row row-glairy display-block">
                                                <div className="box-content">
                                                    { relatedRow7 }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-glairy col-xs-12 col-md-4">
                                <div className="block-aside-part">
                                    <div className="row row-glairy">
                                        <div className="col-glairy col-xs-12 col-md-12">
                                            <div className="row row-glairy">                                                
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-1">
                                                    <div className="box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Belföld</span>
                                                        </div>
                                                    </div>
                                                    { belfold }
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-1">
                                                    <BoxAd
                                                        isBlock={false}
                                                        ad={ 
                                                            <iframe src="https://mindenpercben.hu/r/1213102.html" width="350" height="650" frameborder="0" scrolling="no"></iframe>                                                    
                                                        }
                                                    />
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-2">
                                                    <div className="box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Külföld</span>
                                                        </div>
                                                    </div>
                                                    { kulfold }
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-1">
                                                    <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/netex273.html" width="350" height="320" frameborder="0" scrolling="no"></iframe> } />
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-4">
                                                    <div className="box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Gazdaság</span>
                                                        </div>
                                                    </div>
                                                    { gazdasag }
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-5">
                                                    <div className="box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>TechTud</span>
                                                        </div>
                                                    </div>
                                                    { techtud }
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-1">
                                                    <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/netex323.html" width="350" height="300" frameborder="0" scrolling="no"></iframe> } />
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-7">
                                                    <div className="box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Kultúra</span>
                                                        </div>
                                                    </div>
                                                    { kultura }
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-10">
                                                    <div className="box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Életmód</span>
                                                        </div>
                                                    </div>
                                                    { eletmod }
                                                </div>                                                
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-9">
                                                    <div className="box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Bulvár</span>
                                                        </div>
                                                    </div>
                                                    { bulvar }
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-11">
                                                    <div className="box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Időjárás</span>
                                                        </div>
                                                    </div>
                                                    { idojaras }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BoxAd isBlock={true} ad={ <iframe src="https://mindenpercben.hu/r/1213172.html" width="1100" height="320" frameborder="0" scrolling="no"></iframe> } />
                </div>
            </div>
        </>
    )
}