import React from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'

export default function ArticleSeo({
    title, cover, coverWidth, coverHeight,
    description, keywords,
    canonical, canonicalMobile
}) {
    return (
        <GatsbySeo
            language="hu"
            title={ title }
            description={ description }
            canonical={ canonical }
            metaTags={[{
                name: 'keywords',
                content: keywords
            }]}
            mobileAlternate={{
                media: 'only screen and (max-width: 640px)',
                href: canonicalMobile,
            }}
            openGraph={{
                url: canonical,
                title: title,
                description: description,
                site_name: 'Minden Percben',
                images: [
                    {
                    url: cover,
                    width: coverWidth,
                    height: coverHeight,
                    alt: title,
                    },
                    { url: cover },
                ],         
            }}
            twitter={{
                handle: '@handle',
                site: '@site',
                cardType: 'summary_large_image',
            }}
        />
    )
}